import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import type { AuthSlice } from './authSlice';
import { createAuthSlice } from './authSlice';
import type { BulkTabSlice } from './bulkTabSlice';
import { createBulkTabSlice } from './bulkTabSlice';
import type { FileSlice } from './fileSlice';
import { createFileSlice } from './fileSlice';
import type { FilterState } from './filter-slice';
import { createFilterSlice } from './filter-slice';
import { createFormSlice, type FormSlice } from './form-slice';
import type { InstanceSlice } from './instanceSlice';
import { createInstanceSlice } from './instanceSlice';
import type { PromptSlice } from './promptSlice';
import { createPromptSlice } from './promptSlice';

export const useBoundStore = create<InstanceSlice>()(
  devtools(
    (...a) => ({
      ...createInstanceSlice(...a),
    }),
    {
      name: 'bound-store',
    }
  )
);

export const useInstanceStore = create<InstanceSlice>()(
  devtools(
    (...a) => ({
      ...createInstanceSlice(...a),
    }),
    {
      name: 'instance-store',
    }
  )
);

export const usePromptStore = create<PromptSlice>()(
  devtools(
    (...a) => ({
      ...createPromptSlice(...a),
    }),
    {
      name: 'prompt-store',
    }
  )
);

export const useAuthStore = create<AuthSlice>()(
  devtools(
    (...a) => ({
      ...createAuthSlice(...a),
    }),
    {
      name: 'login-store',
    }
  )
);

export const useFileStore = create<FileSlice>()(
  devtools(
    (...a) => ({
      ...createFileSlice(...a),
    }),
    {
      name: 'file-store',
    }
  )
);

export const useFilterStore = create<FilterState>()(
  devtools(
    (...a) => ({
      ...createFilterSlice(...a),
    }),
    {
      name: 'filter-store',
    }
  )
);

export const useBulkTabStore = create<BulkTabSlice>()(
  persist(
    devtools(
      (...a) => ({
        ...createBulkTabSlice(...a),
      }),
      {
        name: 'bulk-tab-store',
      }
    ),
    {
      name: 'bulk-tab-store',
      partialize: (state) => {
        return {
          trackedBulks: state.trackedBulks,
        };
      },
    }
  )
);

export const useFormStore = create<FormSlice>()(
  devtools((...a) => createFormSlice()(...a), { name: 'form-store' })
);
