import type { StateCreator } from 'zustand';

export interface AuthSlice {
  isAuthorized: boolean;
  setIsAuthorized: (val: boolean) => void;
}

export const createAuthSlice: StateCreator<AuthSlice> = (set) => ({
  isAuthorized: true,
  setIsAuthorized: (val) => {
    set({
      isAuthorized: val,
    });
  },
});
