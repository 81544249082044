import { getCookies } from './cookies';

const appendToObject = (
  obj: Record<string, any>,
  orgId: string,
  isPatch: boolean,
  skipOrganizationOnFieldNames: string[]
) => {
  if (!isPatch) {
    obj.organization = {
      id: orgId,
    };
  }

  // obj.mt_motivation = 'test';

  if (obj.id != null && String(obj.id).length === 0) delete obj.id;

  for (const key in obj) {
    if (obj[key] === null) continue;

    if (key === 'organization' || skipOrganizationOnFieldNames.includes(key)) {
      continue;
    }
    if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
      //for create request, have to make it a method
      if (obj[key].id != null && String(obj[key].id).length === 0) {
        obj[key].id = undefined;
        obj[key].organization = {
          id: orgId,
        };
      } else if (obj[key].id == null) {
        obj[key].organization = {
          id: orgId,
        };
      }
    }

    if (Array.isArray(obj[key])) {
      for (const item of obj[key]) {
        if (typeof item === 'object') {
          if (item.id != null && String(item.id).length === 0) {
            item.id = undefined;
            item.organization = {
              id: orgId,
            };
          }
        }
      }
    }
  }

  return obj;
};

export const appendOrganiztion = async (
  body: Record<string, unknown> | Array<Record<string, unknown>>,
  isPatch?: boolean,
  skipOrganizationOnFieldNames?: string[]
) => {
  const { cookies } = await getCookies();

  if (Array.isArray(body)) {
    const arr = body.map((item) => {
      if (typeof item !== 'object') return item;
      return appendToObject(
        item,
        cookies.organization_id,
        isPatch ?? false,
        skipOrganizationOnFieldNames ?? []
      );
    });

    return arr;
  }

  return appendToObject(
    body,
    cookies.organization_id,
    isPatch ?? false,
    skipOrganizationOnFieldNames ?? []
  );
};
