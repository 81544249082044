import type { BaseInstance } from '@pigello/pigello-matrix';
import type { NextRequest } from 'next/server';
import { fetchApi } from '.';
import { BASE_BACKEND_URL } from '../constants';
import type { RequestData } from '../types';
import { handleResponse } from './handle-response';

type Patch<T extends BaseInstance> = {
  url: string;
  body: Partial<T>;
  req?: NextRequest;
  _requestData?: RequestData;
};

export const patch = async <T extends BaseInstance>({
  body,
  url,
  _requestData,
  req,
}: Patch<T>) => {
  const fullUrl = `${BASE_BACKEND_URL}/${url}`;

  const requestData: RequestData = _requestData ?? {
    type: 'PATCH',
    url: fullUrl,
    options: {
      body: JSON.stringify(body),
    },
  };

  const response = await fetchApi({
    method: 'PATCH',
    url: requestData.url,
    body: requestData.options?.body,
    req,
  });

  return (await handleResponse(response, requestData)) as { data: T };
};
