import { parseJsonFilter } from '@/hooks/use-table-filter/utils';
import { getGlobalFilterQuery } from '@/lib/api/globalFilter';
import { getConfig, type ModelName } from '@pigello/pigello-matrix';
import { cloneDeep } from 'lodash';
import { fetchApi } from '.';
import { BASE_BACKEND_URL } from '../constants';
import type { ListArguments } from '../types';

type GetCount = {
  modelName: ModelName;
  filters?: ListArguments['queryParams']['filters'];
  revalidate?: number;
  overrideUrl?: string;
  overrideUrlReplaceAll?: boolean;
  skipGlobalFilters?: boolean;
  signal?: AbortSignal;
};

export async function getCount({
  modelName,
  filters,
  revalidate,
  overrideUrl,
  overrideUrlReplaceAll,
  skipGlobalFilters,
  signal,
}: GetCount) {
  if (!modelName) {
    throw new Error('Either config or modelName must be provided');
  }
  const config = await getConfig(modelName);
  const clonedFilters = cloneDeep(filters);
  const idsIn = clonedFilters?.id?.__in;
  const idsNotIn = clonedFilters?.id?.['__in!'];

  if (idsIn && typeof idsIn === 'string' && idsIn.includes(',')) {
    clonedFilters.id.__in = idsIn.split(',').sort().join(',');
  }

  if (idsNotIn && typeof idsNotIn === 'string' && idsNotIn.includes(',')) {
    clonedFilters.id['__in!'] = idsNotIn.split(',').sort().join(',');
  }

  let backendUrl = `${BASE_BACKEND_URL}/${config.listUrl}/`;

  if (overrideUrl) {
    if (overrideUrlReplaceAll) {
      backendUrl = `${BASE_BACKEND_URL}/${overrideUrl}${(overrideUrl as string).endsWith('/') ? '' : '/'}`;
    } else {
      backendUrl = `${BASE_BACKEND_URL}/${config.listUrl}/${overrideUrl}/`;
    }
  }
  const completeUrl = `${backendUrl}?${parseJsonFilter(clonedFilters ?? {})}${
    modelName !== 'organization'
      ? await getGlobalFilterQuery(skipGlobalFilters)
      : ''
  }&_statistics=true`;

  const res = await fetchApi({
    method: 'GET',
    url: completeUrl,
    next: {
      revalidate,
    },
    signal,
  });

  if (!res.ok) {
    throw {
      status: res.status,
      message: res.statusText,
      url: res.url,
      errorData: await res.json(),
    };
  }

  const data = (await res.json()) as {
    statistics: { count: number };
  };

  return data.statistics.count;
}
