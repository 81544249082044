import type {
  BaseInstance,
  IBaseInstanceConfig,
  ModelName,
} from '@pigello/pigello-matrix';
import type {
  UseMutationOptions,
  UseQueryOptions,
} from '@tanstack/react-query';
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { get } from './api/get';
import { getCount } from './api/get-count';
import { getList } from './api/get-list';
import type {
  ErrorResponse,
  ListArguments,
  useCreateGenericInstanceProps,
  useDeleteGenericInstanceProps,
  useGetGenericInfiniteListProps,
  useGetGenericInstanceProps,
  useGetGenericListProps,
  useMutateGenericInstanceProps,
} from './types';

// const DEFAULT_STATE_TIME = 60 * 5 * 1000;
const DEFAULT_STATE_TIME = Infinity;

export const QUERY_KEYS = {
  LIST: 'list',
  COUNT: 'count',
  ALL_INSTANCES: 'allinstances',
  ALL_IDS: 'allids',
} as const;

export const useGetInstance = <C extends BaseInstance>(
  options: useGetGenericInstanceProps<C> & {
    modelName: ModelName;
    config?: IBaseInstanceConfig<C>;
  }
) => {
  return useQuery({
    queryKey: [
      options.modelName,
      {
        modelName: options.modelName,
        id: options.id,
        nested: options.nested,
      },
    ],
    queryFn: async () => {
      const res = await get({
        modelName: options.modelName,
        config: options.config,
        id: options.id,
        nested: options.nested,
        overrideUrl: options.overrideUrl,
      });
      return res;
    },
    refetchOnWindowFocus: false,
    staleTime: DEFAULT_STATE_TIME,
    ...options,
  });
};

export const useGetList = <C extends BaseInstance>(
  options: useGetGenericListProps<C> & {
    modelName?: ModelName;
    config?: IBaseInstanceConfig<C>;
  }
) =>
  useQuery({
    queryKey: [
      options.modelName,
      QUERY_KEYS.LIST,
      {
        modelName: options.modelName,
        nested: options.nested,
        queryParams: options.queryParams,
        overrideUrl: options?.overrideUrl,
        overrideUrlReplaceAll: options?.overrideUrlReplaceAll,
        ...options.queryParams,
      },
    ],
    queryFn: async ({ signal }) => {
      const res = await getList({
        modelName: options.modelName,
        config: options.config,
        nested: options.nested,
        queryParams: options.queryParams,
        fetchAllManyRelations: options.fetchAllManyRelations,
        overrideUrl: options?.overrideUrl,
        overrideUrlReplaceAll: options?.overrideUrlReplaceAll,
        signal,
      });
      return res.data;
    },
    refetchOnWindowFocus: false,
    staleTime: DEFAULT_STATE_TIME,
    ...options,
  });

export const useGetInfiniteList = <C extends BaseInstance>(
  options: useGetGenericInfiniteListProps<C> & {
    modelName: ModelName;
    config?: IBaseInstanceConfig<C>;
  }
) =>
  useInfiniteQuery({
    ...options,
    queryKey: [
      options.modelName,
      QUERY_KEYS.LIST,
      {
        modelName: options.modelName,
        nested: options.nested,
        queryParams: options.queryParams,
        overrideUrl: options?.overrideUrl,
        overrideUrlReplaceAll: options?.overrideUrlReplaceAll,
        ...options.queryParams,
      },
    ],
    queryFn: async ({ pageParam, signal }) => {
      const res = await getList({
        modelName: options.modelName,
        config: options.config,
        nested: options.nested,
        queryParams: {
          ...options.queryParams,
          page: pageParam,
        },
        overrideUrl: options?.overrideUrl,
        overrideUrlReplaceAll: options?.overrideUrlReplaceAll,
        signal,
      });
      return res.data;
    },
    getNextPageParam: (lastPage) => {
      if (lastPage?.meta && lastPage.meta?.page >= lastPage.meta?.page_amount) {
        return undefined;
      }
      return (lastPage.meta?.page ?? 0) + 1;
    },
    staleTime: DEFAULT_STATE_TIME,
    refetchOnWindowFocus: false,
    initialPageParam: 1,
  });

export const useMutateGenericInstance = <Instance extends BaseInstance>(
  mutationFn: (
    options: useMutateGenericInstanceProps<Instance>
  ) => Promise<Instance>,
  options: UseMutationOptions<Instance, ErrorResponse, unknown, unknown>,
  modelName: ModelName
) => {
  const queryClient = useQueryClient();
  return useMutation({
    ...options,
    mutationFn,
    onSuccess: (data, variables, context) => {
      options.onSuccess?.(data, variables, context);
      // Invalidate the instance query
      queryClient.invalidateQueries({
        queryKey: [modelName, { id: data.id }],
      });
      // Invalidate the list query
      queryClient.invalidateQueries({
        queryKey: [modelName, QUERY_KEYS.LIST],
      });
      queryClient.invalidateQueries({
        queryKey: [modelName, QUERY_KEYS.ALL_INSTANCES],
      });
      queryClient.invalidateQueries({
        queryKey: [modelName, QUERY_KEYS.ALL_IDS],
      });
      queryClient.invalidateQueries({
        queryKey: [modelName, 'allids'],
      });
    },
  });
};

export const useCreateGenericInstance = <Instance extends BaseInstance>(
  mutationFn: (
    options: useCreateGenericInstanceProps<Instance>
  ) => Promise<Instance>,
  options: UseMutationOptions<Instance, ErrorResponse, unknown, unknown>,
  modelName: ModelName
) => {
  const queryClient = useQueryClient();
  return useMutation({
    ...options,
    mutationFn,
    onSuccess: (data, variables, context) => {
      options.onSuccess?.(data, variables, context);
      // Invalidate the instance query
      queryClient.invalidateQueries({
        queryKey: [modelName, { id: data.id }],
      });
      // Invalidate the list query
      queryClient.invalidateQueries({
        queryKey: [modelName, QUERY_KEYS.LIST],
      });
      queryClient.invalidateQueries({
        queryKey: [modelName, QUERY_KEYS.ALL_INSTANCES],
      });
      queryClient.invalidateQueries({
        queryKey: [modelName, QUERY_KEYS.ALL_IDS],
      });
    },
  });
};

export const useDeleteGenericInstance = <Instance extends BaseInstance>(
  mutationFn: (
    options: useDeleteGenericInstanceProps<Instance>
  ) => Promise<Instance>,
  options: UseMutationOptions<Instance, ErrorResponse, unknown, unknown>,
  modelName: ModelName
) => {
  const queryClient = useQueryClient();
  return useMutation({
    ...options,
    mutationFn,
    onSuccess: (data, variables, context) => {
      options.onSuccess?.(data, variables, context);
      // Invalidate the instance query
      queryClient.invalidateQueries({ queryKey: [modelName, { modelName }] });
      // Invalidate the tablelist query
      queryClient.invalidateQueries({
        queryKey: [modelName, QUERY_KEYS.LIST],
      });
      queryClient.invalidateQueries({
        queryKey: [modelName, QUERY_KEYS.ALL_INSTANCES],
      });
      queryClient.invalidateQueries({
        queryKey: [modelName, QUERY_KEYS.ALL_IDS],
      });
    },
  });
};

export const useGetCount = (
  options: {
    modelName: ModelName;
    filters?: ListArguments['queryParams']['filters'];
    revalidate?: number;
    overrideUrl?: string;
    overrideUrlReplaceAll?: boolean;
    skipGlobalFilters?: boolean;
  } & Omit<UseQueryOptions<unknown, unknown, number>, 'queryKey' | 'queryFn'>
) => {
  return useQuery({
    queryKey: [
      options.modelName,
      'count',
      {
        modelName: options.modelName,
        filters: options.filters,
      },
    ],
    queryFn: async ({ signal }) => {
      const res = await getCount({
        modelName: options.modelName,
        filters: options.filters,
        skipGlobalFilters: options.skipGlobalFilters,
        signal,
      });
      return res;
    },
    refetchOnWindowFocus: false,
    staleTime: DEFAULT_STATE_TIME,
    retry: 1,
    ...options,
  });
};
