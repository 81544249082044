import type { StateCreator } from 'zustand';

export type FilterState = {
  filters: Record<string, string>;
  search: string;
  order: string[];
  pagination: {
    pageIndex: number;
    pageSize: number;
  };
  setPagination: (pagination: { pageIndex: number; pageSize: number }) => void;
  setSearch: (search: string) => void;
  setOrder: (order: string[]) => void;
  setFilter: (parsedFilter: Record<string, string>) => void;
  clearFilter: (key: string) => void;
  clearAllFilters: () => void;
  clearAll: () => void;
};

export const createFilterSlice: StateCreator<FilterState> = (set) => ({
  filters: {},
  search: '',
  pagination: {
    pageIndex: 0,
    pageSize: 25,
  },
  order: [],
  setPagination: (pagination) => {
    set({ pagination });
  },
  setSearch: (search) => {
    set((state) => ({
      search,
      pagination: { ...state.pagination, pageIndex: 0 },
    }));
  },
  setOrder: (order) => {
    set({ order });
  },
  setFilter: (parsedFilter) => {
    set((state) => ({
      pagination: { ...state.pagination, pageIndex: 0 },
      filters: {
        ...state.filters,
        ...parsedFilter,
      },
    }));
  },
  clearFilter: (key) => {
    set((state) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [key]: _, ...restFilters } = state.filters;
      return {
        filters: restFilters,
      };
    });
  },
  clearAllFilters: () => {
    set({ filters: {} });
  },
  clearAll: () => {
    set({
      filters: {},
      search: '',
      pagination: { pageIndex: 0, pageSize: 25 },
      order: [],
    });
  },
});
