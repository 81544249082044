import type {
  BaseInstance,
  IBaseInstanceConfig,
} from '@pigello/pigello-matrix';
import { getCookies } from 'cookies-next';
import type { StateCreator } from 'zustand';
import { useBulkTabStore } from '.';
import type { BulkWindowId, TrackedBulk } from './types';

type MessageType = { id: string } & Record<string, unknown>;

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
type MessageCallback = (payload: MessageType, evt: MessageEvent<any>) => void;

export type BulkTabOpenOptions<Instance extends BaseInstance> = {
  mode: TrackedBulk['mode'];
  config: IBaseInstanceConfig<Instance>;
  //for opening minimized bulk
  winId?: string;
} & (
  | {
      mode: 'create';
    }
  | {
      mode: 'copy';
      instances: (Instance | string)[];
    }
  | {
      mode: 'edit';
      instances: (Instance | string)[];
    }
  | {
      mode: 'download';
      columns: string[];
      settings?: {
        totalInstancesAmount: number;
        order: string[];
        query: Record<string, string>;
      };
      instances?: (Instance | string)[];
    }
);

export interface BulkTabSlice {
  // winId -> TrackedBulk
  trackedBulks: Record<BulkWindowId, TrackedBulk>;
  openNewBulk: <Instance extends BaseInstance>(
    opts: BulkTabOpenOptions<Instance>
  ) => void;
  hasEventListener: boolean;
  handleGlobalEventListener: VoidFunction;
  onUnload: VoidFunction;
  //winId -> msgId -> callbacks
  listeners: Record<BulkWindowId, Record<string, MessageCallback[]>>;
  onMessage: (winId: BulkWindowId, msgId: string, cb: MessageCallback) => void;
  activateTrackedBulk: (winId: BulkWindowId) => void;
  initialize: VoidFunction;
  clearTrackedBulk: (tracked: TrackedBulk) => void;
}

declare global {
  interface Window {
    _bulk_tabs_windows: Record<BulkWindowId, Window>;
  }
}

const BULK_TAB_TYPE_TEXT: Record<TrackedBulk['mode'], string> = {
  copy: 'kopierande',
  create: 'skapande',
  edit: 'redigerande',
  //won't be rendered
  download: 'download',
};

const BULK_TAB_ORIGINS: Record<string, string> = {
  local: 'http://localhost:5173',
  dev: 'https://import-demo.pigello.io',
  prod: 'https://import.pigello.io',
};

const BULK_TAB_ORIGIN =
  BULK_TAB_ORIGINS[process.env.NEXT_PUBLIC_CLIENT_TYPE ?? 'prod'];

const BULK_TAB_HANDSHAKE_TRIES = 10;

const getWindowUrl = (trackedBulk: TrackedBulk) => {
  const baseUrl = `${BULK_TAB_ORIGIN}/?id=${trackedBulk.id}&mode=${trackedBulk.mode}&modelName=${trackedBulk.modelName}`;
  return baseUrl;
};

const getWindowTarget = (trackedBulk: TrackedBulk) => {
  return `${trackedBulk.id}`;
};

const hasWindow = (winId: BulkWindowId) => {
  if (!window._bulk_tabs_windows) return false;

  return winId in window._bulk_tabs_windows;
};

const registerBulkTabOnWindow = (winId: BulkWindowId, win: Window) => {
  if (!window._bulk_tabs_windows) window._bulk_tabs_windows = {};

  window._bulk_tabs_windows[winId] = win;
};

const getWindow = (winId: BulkWindowId) => {
  if (!hasWindow(winId))
    throw new Error('getWindow() was called without hasWindow()');
  return window._bulk_tabs_windows[winId];
};

const sendMessage = (winId: BulkWindowId, payload: MessageType) => {
  if (!hasWindow(winId)) return;

  const win = getWindow(winId);

  win.postMessage(
    {
      ...payload,
    },
    BULK_TAB_ORIGIN
  );
};

const getTrackedBulk = (winId: BulkWindowId): TrackedBulk | undefined => {
  return useBulkTabStore.getState().trackedBulks[winId];
};

const regainWindowReference = (winId: BulkWindowId) => {
  const tracked = getTrackedBulk(winId);

  if (!tracked) return false;

  const referenceWindow = window.open('', getWindowTarget(tracked));

  if (!referenceWindow) return false;

  registerBulkTabOnWindow(winId, referenceWindow);

  return true;
};

const setupDefaultListeners = (winId: BulkWindowId) => {
  useBulkTabStore.getState().onMessage(winId, 'ON_WINDOW_CLOSE', () => {
    const tracked = getTrackedBulk(winId);

    if (!tracked) return;

    useBulkTabStore.getState().clearTrackedBulk(tracked);
  });

  useBulkTabStore.getState().onMessage(winId, 'ON_WINDOW_MINIMIZE', () => {
    const tracked = getTrackedBulk(winId);

    if (!tracked) return;

    //tell window keeper provider
    window._minimize_bulk(tracked);
  });

  useBulkTabStore.getState().onMessage(winId, 'GET_AUTH_CREDENTIALS', () => {
    const tracked = getTrackedBulk(winId);

    if (!tracked) return;

    const { token, user_id, customer_id, organization_id } = getCookies();

    sendMessage(tracked.id, {
      id: 'ON_AUTH_CREDENTIALS',
      credentials: {
        token,
        user_id,
        customer_id,
        organization_id,
      },
    });
  });

  useBulkTabStore.getState().onMessage(winId, 'FETCH_WINDOW_SETTINGS', () => {
    const tracked = getTrackedBulk(winId);

    if (!tracked) return;

    if (!tracked.instanceIds) {
      sendMessage(tracked.id, {
        id: 'ON_WINDOW_SETTINGS',
        data: {
          instanceIds: null,
        },
      });

      return;
    }

    sendMessage(tracked.id, {
      id: 'ON_WINDOW_SETTINGS',
      data: {
        instanceIds: tracked.instanceIds.join(','),
      },
    });
  });

  useBulkTabStore
    .getState()
    .onMessage(winId, 'FETCH_WINDOW_DOWNLOAD_SETTINGS', () => {
      const tracked = getTrackedBulk(winId);

      if (!tracked) return;

      sendMessage(tracked.id, {
        id: 'ON_WINDOW_DOWNLOAD_SETTINGS',
        data: tracked.downloadData,
      });
    });
};

const establishConnection = (winId: BulkWindowId, reactivation = false) => {
  if (!hasWindow(winId)) return;

  let count = 0;

  const interval = setInterval(() => {
    console.log('trying to establish connection with', winId, count);

    if (count >= BULK_TAB_HANDSHAKE_TRIES) {
      console.log('failed to activate bulk tab', winId);
      clearInterval(interval);
      return;
    }

    count++;

    sendMessage(winId, {
      id: reactivation ? 'ON_WINDOW_RE_ACTIVATION' : 'ON_WINDOW_ACTIVATION',
    });
  }, 1000);

  useBulkTabStore.getState().onMessage(winId, 'GOT_WINDOW_ACTIVATION', () => {
    clearInterval(interval);

    setupDefaultListeners(winId);

    useBulkTabStore.getState().activateTrackedBulk(winId);
  });
};

const getTabTitleText = (mode: TrackedBulk['mode']) => {
  const prefix = 'Mas';
  const suffix = BULK_TAB_TYPE_TEXT[mode];

  if (suffix.startsWith('s')) {
    return `${prefix}${suffix}`;
  }

  return `${prefix}s${suffix}`;
};

export const createBulkTabSlice: StateCreator<BulkTabSlice> = (set, get) => ({
  trackedBulks: {},
  hasEventListener: false,
  initialize: () => {
    get().handleGlobalEventListener();
    // biome-ignore lint/complexity/noForEach: can optimize to for of loop if necessary
    Object.values(get().trackedBulks).forEach((trackedBulk) => {
      if (!trackedBulk.active) return get().clearTrackedBulk(trackedBulk);

      const success = regainWindowReference(trackedBulk.id);

      if (!success) return get().clearTrackedBulk(trackedBulk);

      establishConnection(trackedBulk.id, true);
    });
  },
  clearTrackedBulk: (trackedBulk) => {
    const newTracked = get().trackedBulks;

    delete newTracked[trackedBulk.id];

    set({
      trackedBulks: newTracked,
    });
  },
  handleGlobalEventListener: () => {
    if (get().hasEventListener || typeof window === 'undefined') return;

    window.addEventListener('message', (evt) => {
      if (evt.origin !== BULK_TAB_ORIGIN) return;

      if (typeof evt.data !== 'object' || !('_bulk_tab_win_id' in evt.data)) {
        console.log('malformed window message', evt.data);
        return;
      }

      const listeners = get().listeners;

      const winId = evt.data._bulk_tab_win_id;

      if (!(winId in listeners)) return;

      if (typeof evt.data !== 'object' || !('id' in evt.data)) {
        console.log('malformed window message', evt.data);
        return;
      }

      if (!(evt.data.id in listeners[winId])) return;

      // biome-ignore lint/complexity/noForEach: can optimize to for of loop if necessary
      listeners[winId][evt.data.id].forEach((cb) => cb(evt.data, evt));
    });

    set({
      hasEventListener: true,
    });
  },
  onUnload: () => {
    // biome-ignore lint/complexity/noForEach: can optimize to for of loop if necessary
    Object.values(get().trackedBulks).forEach((trackedBulk) => {
      if (!trackedBulk.active) return;

      sendMessage(trackedBulk.id, {
        id: 'ON_MAIN_WINDOW_UNLOAD',
      });
    });
  },
  listeners: {},
  onMessage: (winId, msgId, cb) => {
    const currentListeners = get().listeners;

    if (!(winId in currentListeners)) currentListeners[winId] = {};

    if (!(msgId in currentListeners[winId])) {
      currentListeners[winId][msgId] = [cb];
    } else {
      currentListeners[winId][msgId] = [...currentListeners[winId][msgId], cb];
    }
  },
  openNewBulk: <Instance extends BaseInstance>(
    opts: BulkTabOpenOptions<Instance>
  ) => {
    if (typeof window === 'undefined') return;

    const trackedBulk: TrackedBulk = {
      id: opts.winId ?? `bulk-tab-${crypto.randomUUID()}`,
      displayName: `${getTabTitleText(opts.mode)} - ${opts.config.verboseNamePlural}`,
      modelName: opts.config.modelName,
      mode: opts.mode,
      active: false,
    };

    if ('instances' in opts && !opts.winId && opts.instances) {
      if (opts.instances.length === 0) {
        console.warn(
          'Unable to open bulk tab for mode',
          opts.mode,
          'instances.length was 0'
        );
        return;
      }

      if (typeof opts.instances[0] === 'string')
        trackedBulk.instanceIds = opts.instances as string[];
      else
        trackedBulk.instanceIds = (opts.instances as Instance[]).map(
          (inst) => inst.id
        );
    }

    if (opts.mode === 'download') {
      trackedBulk.downloadData = {
        columns: opts.columns,
        settings: opts.settings,
      };
    }

    const win = window.open(
      getWindowUrl(trackedBulk),
      getWindowTarget(trackedBulk)
    );

    if (!win) return;

    registerBulkTabOnWindow(trackedBulk.id, win);

    get().handleGlobalEventListener();

    const currentTrackedBulks = get().trackedBulks;

    set({
      trackedBulks: {
        ...currentTrackedBulks,
        [trackedBulk.id]: trackedBulk,
      },
    });

    establishConnection(trackedBulk.id);
  },
  activateTrackedBulk: (winId) => {
    const trackedBulk = getTrackedBulk(winId);

    if (!trackedBulk) {
      sendMessage(winId, {
        id: 'TERMINATE_WINDOW',
      });
      return;
    }
    trackedBulk.active = true;

    set({
      trackedBulks: {
        ...get().trackedBulks,
        [trackedBulk.id]: trackedBulk,
      },
    });
  },
});
