import type { StateCreator } from 'zustand';

export type StoredFile = {
  [md5Hash: string]: {
    b64: string;
    name: string;
    size: number;
  };
};

export type FailedFile = {
  [md5Hash: string]: {
    data: FormData;
    name: string;
    postUrl: string;
    b64: string;
    size: number;
    uploaded: Date;
  };
};

export interface FileSlice {
  files: StoredFile;
  failedFiles: FailedFile;
  uploadedFiles: StoredFile;
  setUploadedFile: (props: StoredFile) => void;
  clearUploadedFiles: () => void;
  setFile: (props: StoredFile) => void;
  setFailedFile: (props: FailedFile) => void;
  clearFailedFiles: () => void;
  removeFile: (md5Hash: string) => void;
  clearFiles: () => void;
}

export const createFileSlice: StateCreator<FileSlice> = (set, get) => ({
  files: {},
  uploadedFiles: {},
  failedFiles: {},
  setUploadedFile: (props) => {
    set({
      uploadedFiles: {
        ...get().uploadedFiles,
        ...props,
      },
    });
  },
  clearUploadedFiles: () => {
    set({
      uploadedFiles: {},
    });
  },
  setFile: (props) => {
    set({
      files: {
        ...get().files,
        ...props,
      },
    });
  },
  setFailedFile: (props) => {
    set({
      failedFiles: {
        ...get().failedFiles,
        ...props,
      },
    });
  },
  clearFailedFiles: () => {
    set({
      failedFiles: {},
    });
  },
  removeFile: (md5Hash: string) => {
    const newFiles = { ...get().files };
    delete newFiles[md5Hash];
    set({
      files: newFiles,
    });
  },
  clearFiles: () => {
    set({
      files: {},
    });
  },
});
