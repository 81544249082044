import type { BaseInstance } from '@pigello/pigello-matrix';
import type { StateCreator } from 'zustand';

export interface FormSlice<T extends BaseInstance = BaseInstance> {
  data: Partial<T>;
  setData: (data: Partial<T>) => void;
  errors: Record<string, boolean>;
  setErrors: (errors: Record<string, boolean>) => void;
}

export function createFormSlice<
  T extends BaseInstance = BaseInstance,
>(): StateCreator<FormSlice<T>> {
  return (set) => ({
    data: {},
    setData: (data) => {
      set({
        data,
      });
    },
    errors: {},
    setErrors: (errors) => {
      set({
        errors,
      });
    },
  });
}
