import type { BaseInstance, ModelName } from '@pigello/pigello-matrix';
import type { StateCreator } from 'zustand';

interface SavedInstance<Instance extends BaseInstance> {
  instance: Partial<Instance>;
  modelName: ModelName;
  identifier: string;
  // mutatedFields: Array<keyof Instance>;
}

export interface InstanceSlice<Instance extends BaseInstance = BaseInstance> {
  instances: Map<string, SavedInstance<Instance>>;
  addInstance: (
    identifier: string,
    modelName: ModelName,
    instance: Partial<Instance>
  ) => void;
  updateInstance: (identifier: string, data: Partial<Instance>) => void;
  hasInstance: (identifier: string) => boolean;
  getInstance: (identifier: string) => Partial<Instance> | null;
  removeInstance: (identifier: string) => boolean;
  // updateInstance: (identifier: string, newData: Partial<Instance>) => void;
}

export const createInstanceSlice: StateCreator<InstanceSlice<BaseInstance>> = (
  set,
  get
) => ({
  instances: new Map(),
  addInstance: (identifier, modelName, instance) => {
    // const identifier = `inst_${crypto.randomUUID()}`;

    const currentMap = get().instances;

    console.log('adding instance w/identifier', identifier);

    currentMap.set(identifier, {
      instance,
      modelName,
      identifier,
      // mutatedFields: [],
    });

    set({
      instances: currentMap,
    });

    // return identifier;
  },
  updateInstance: (identifier, data) => {
    const currentMap = get().instances;

    const inst = currentMap.get(identifier);

    if (!inst)
      throw Error(
        `Unable to find instance with identifier ${identifier} in instance slice`
      );

    inst.instance = data;

    currentMap.set(identifier, inst);

    set({
      instances: currentMap,
    });
  },
  getInstance: (identifier) => {
    const storedInstance = get().instances.get(identifier);

    if (!storedInstance) return null;

    return storedInstance.instance;
  },
  hasInstance: (identifier) => {
    return Boolean(get().instances.get(identifier));
  },
  removeInstance: (identifier) => {
    const instances = get().instances;

    if (!instances.has(identifier)) return false;

    instances.delete(identifier);

    console.log('removed instance, new instances', instances);

    set({
      instances,
    });

    return true;
  },
  // updateInstance: (identifier, newData) => {
  //   // const inst = get().instances.find((i) => i.identifier === identifier);

  //   const currentMap = get().instances;

  //   const inst = currentMap.get(identifier);

  //   if (!inst)
  //     throw Error(
  //       `Unable to find instance with identifier ${identifier} in instance slice`
  //     );

  //   const newInst = cloneDeep(inst);

  //   for (const [key, value] of Object.entries(newData)) {
  //     if (value === inst.initialInstance[key as keyof BaseInstance]) {
  //       //revert mutate values if same as initial
  //       inst.mutatedFields.splice(
  //         inst.mutatedFields.indexOf(key as keyof BaseInstance),
  //         1
  //       );
  //     } else {
  //       inst.mutatedFields.push(key as keyof BaseInstance);
  //     }

  //     newInst.instance[key as keyof BaseInstance] = value;
  //   }

  //   currentMap.set(identifier, newInst);

  //   set({
  //     instances: currentMap,
  //   });
  // },
});
