import type {
  BaseInstance,
  IBaseInstanceConfig,
} from '@pigello/pigello-matrix';
import type { StateCreator } from 'zustand';

export type PromptInfo = {
  title?: string;
  callback?: (input: string) => void;
  instance?: BaseInstance;
  type: 'motivation';
  config?: IBaseInstanceConfig<BaseInstance>;
};
export interface PromptSlice {
  current: PromptInfo | undefined;
  prompt: (props: PromptInfo, callback: (input: string) => void) => void;
  reset: () => void;
  input: string;
  setInput: (val: string) => void;
}

export const createPromptSlice: StateCreator<PromptSlice> = (set) => ({
  prompt: (prompt, callback) => {
    set({
      current: { ...prompt, callback },
    });
  },
  reset: () => {
    set({
      current: undefined,
      input: '',
    });
  },
  current: undefined,
  input: '',
  setInput: (val) => {
    set({
      input: val,
    });
  },
});
